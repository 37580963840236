<template>
  <div class="">
    <PreventUnload :when="hasChanges" />
    <v-navigation-drawer app class="ma-0 pa-0" v-model="mostrar">
      <opciones tipo="precotizacion" />
    </v-navigation-drawer>
    <v-app-bar app class="mb-0 pb-0">
      <v-app-bar-nav-icon @click="mostrar = !mostrar"></v-app-bar-nav-icon>
      <h3 color="white--text">REGISTRO DE PRE COTIZACIÓN</h3>
    </v-app-bar>
    <v-container elevation="10" class="px-10" fluid>
      <v-card>
        <!-- CONTENIDO -->
        <div class="">
          <h3 class="titulo">
            <center>REGISTRO DE PRE COTIZACIÓN</center>
          </h3>

          <preCotizacion />
        </div>

        <!-- CONTENIDO -->
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import opciones from "@/components/comun/opciones.vue";
import funcion from "@/mixins/funciones";
import PreventUnload from "vue-prevent-unload";
export default {
  mixins: [funcion],

  name: "reporteCotizacionView",
  components: {
    PreventUnload,
    preCotizacion: () =>
      import("@/components/preCotizacion/preCotizacionComponents.vue"),

    opciones,
  },
  data() {
    return {
      mostrar: true,
      hasChanges: true,
      tablaFlag: {
        tabla: true,
        rangoDias: false,
      },
      tab: null,
      items: ["Tabla", "STATUS COT X RANGO DIAS", "videos", "images", "news"],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },

  computed: {
    ...mapState(["titulo"]),
  },
  mounted() {
    let opciones = [
      {
        id: 1,
        nombre: "Pre Cotización",
        class: "active",
        url: "",
      },
    ];
    this.obtenerDatosCliente();
    this.opcioneMenuLista(opciones);
  },
  methods: {
    ...mapActions([
      "getQuoteStatus",
      "getEntities",
      "getModality",
      "getShipment",
      "getIncoterms",
      "getPortBegin",
      "getPortEnd",
      "obtenerDatosCliente",
      "getBegEndList",
      "getCityPricing",
      "getTown",
      "getMarketingList",
    ]),
    ...mapMutations([
      "opcioneMenuLista",
      "setModality",
      "setShipment",
      "setIncoterms",
      "setearServices",
      "setDatosClientes",
      "setPrecotizarFlag",
      "setearDatos",
    ]),
  },
  async beforeMount() {
    await this.setearDatos();
    await this.getQuoteStatus();
    await this.getEntities();
    await this.getModality();
    await this.getShipment();
    await this.getIncoterms();
    // await this.getPortBegin();
    // await this.getPortEnd();
    await this.getCityPricing();
    await this.setearServices();
    await this.getTown();
    await this.getMarketingList();

    // this.setPrecotizarFlag(true);
  },
};
</script>

<style scope>
html {
  scroll-behavior: smooth;
}

.titulo {
  color: white !important;
  padding: 0.7rem;
  background: rgb(32, 55, 100);
}
/* .v-tab {
  transform: scale(1.1);
} */
</style>
